import type { SearchResponse } from '@algolia/client-search';
import { useEffect } from 'react';

import { CmsApiPage } from 'modules/cms/api/types/CmsApiPage';
import { PostSearchAlgoliaHit } from 'modules/postSearch/algolia/types/PostSearchAlgoliaHit';
import { useModal } from 'zustand-stores/modalStore';

import { debouncedTrackDisplayedPosts } from './postSearchPageTrackDisplayedPosts';

type Args = {
  content: CmsApiPage;
  results: SearchResponse<PostSearchAlgoliaHit> | undefined;
};

export function usePostSearchPageTrackDisplayedListings({
  content,
  results,
}: Args) {
  const { modal } = useModal();
  const modalShown = Boolean(modal);

  useEffect(() => {
    if (modalShown || !results) return;
    debouncedTrackDisplayedPosts({ results, subsite: content.subsite });
  }, [content.subsite, modalShown, results]);
}
