import type { SearchResponse } from '@algolia/client-search';
import invariant from 'invariant';

import {
  CmsApiAuthorSlug,
  CmsApiCategorySlug,
  CmsApiSubsiteId,
  CmsApiTagSlug,
} from 'modules/cms/api/types/CmsApiTypedId';
import { searchAlgoliaConfig } from 'modules/search/algolia/searchAlgoliaConfig';
import { algoliaSearch } from 'utils/algolia/algoliaSearch';
import { algoliaQueryStringArrayFilter } from 'utils/algolia/helpers/algoliaQueryStringArrayFilter';
import { algoliaQueryStringFilter } from 'utils/algolia/helpers/algoliaQueryStringFilter';

import { postSearchAlgoliaGetIndex } from './postSearchAlgoliaGetIndex';
import { PostSearchAlgoliaHit } from './types/PostSearchAlgoliaHit';

type Args = {
  query: string;
  pageIndex: number;
  subsiteId: CmsApiSubsiteId;
  includedSubsiteIds: CmsApiSubsiteId[];
  locale: UserLocale;
  categorySlugs: CmsApiCategorySlug[];
  tagSlug: CmsApiTagSlug | undefined;
  authorSlug: CmsApiAuthorSlug | undefined;
};

export function postSearchAlgoliaFetch({
  query,
  pageIndex,
  subsiteId,
  includedSubsiteIds,
  locale,
  categorySlugs,
  tagSlug,
  authorSlug,
}: Args): Promise<SearchResponse<PostSearchAlgoliaHit>> {
  let index = postSearchAlgoliaGetIndex();
  invariant(typeof index === 'string', 'Post search index not defined');

  // use replica that's sorted differently when query is provided by user input
  if (query) index = `${index}-relevant`;

  const filters = [
    algoliaQueryStringFilter('pageType', 'POST'),
    algoliaQueryStringFilter('locale', locale),
    algoliaQueryStringArrayFilter('categories', categorySlugs),
    algoliaQueryStringFilter('author.slug', authorSlug),
    algoliaQueryStringFilter('tags.slug', tagSlug),
  ]
    .filter(Boolean)
    .join(' AND ');

  return algoliaSearch<PostSearchAlgoliaHit>({
    config: searchAlgoliaConfig(),
    query,
    options: {
      index,
      hitsPerPage: 20,
      page: pageIndex,
      facets: ['*', 'subsiteId', 'pageType', 'categoryIds', 'locale'],
      facetFilters: [
        [...includedSubsiteIds, subsiteId].map((id) => `subsiteId:${id}`),
      ],
      filters,
      attributesToSnippet: ['description:20'],
      attributesToRetrieve: ['*'],
      // TODO: list attributes,
      removeStopWords: true,
      ignorePlurals: true,
      advancedSyntax: true,
      queryLanguages: [locale],
    },
  });
}
