import styled from 'styled-components';

import { cssBreakpoints, gutterWidth, maxWidth } from 'theme/theme';

export const SearchResultsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  max-width: ${maxWidth[3]}px;
  margin: 0 auto;

  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    margin-top: 72px;
    width: 100%;
    height: calc(100% + ${gutterWidth / 2}px);
  }

  @media all and (min-width: ${cssBreakpoints.lgUp}) {
    &::before {
      box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
      border-radius: 12px;
    }
  }
`;
