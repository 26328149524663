import { SearchLoadingSectionLine } from 'modules/search/components/Loading/SearchLoadingSectionLine';

export function PostSearchLoadingFilters() {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        marginTop: 16,
      }}
    >
      <SearchLoadingSectionLine width="80%" height={32} />
      <SearchLoadingSectionLine width="100%" height={32} />
      <SearchLoadingSectionLine width="70%" height={20} />
      <SearchLoadingSectionLine width="80%" height={20} />
    </div>
  );
}
