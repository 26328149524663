import styled from 'styled-components';

import { bodyMicroText } from 'theme/theme';

export const PillText = styled.span`
  ${bodyMicroText};

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
