import { MouseEvent, useMemo } from 'react';

import { Button } from 'components/Button/Button';
import { Divider } from 'components/Divider';
import { AnimatedCheckmark } from 'components/Icon/AnimatedCheckmark';
import { Icon } from 'components/Icon/Icon';
import { H1 } from 'components/Text/H1';
import { PostSearchInputContainer } from 'containers/PostSearchInputContainer';
import { CmsApiAuthor } from 'modules/cms/api/types/CmsApiAuthor';
import { CmsApiCategory } from 'modules/cms/api/types/CmsApiCategory';
import { CmsApiSubsite } from 'modules/cms/api/types/CmsApiSubsite';
import { CmsApiTag } from 'modules/cms/api/types/CmsApiTag';
import {
  CmsApiAuthorSlug,
  CmsApiCategorySlug,
  CmsApiTagSlug,
} from 'modules/cms/api/types/CmsApiTypedId';
import { PostSearchCategoryFilter } from 'modules/postSearch/components/CategoryFilter/PostSearchCategoryFilter';
import { PostSearchIncludedSubsites } from 'modules/postSearch/components/IncludedSubsites/PostSearchIncludedSubsites';
import { PostSearchLoadingFilters } from 'modules/postSearch/components/Loading/PostSearchLoadingFilters';
import { PostSearchResultsFilterPill } from 'modules/postSearch/components/Results/PostSearchResultsFilterPill';

import {
  PostSearchSidebarPillsContainer,
  PostSearchSidebarSearchBlurb,
  PostSearchSidebarSearchLinksHolder,
  SubscribeButtonContents,
} from './PostSearchSidebar.styled';
import { PostSearchSidebarParentSubsiteHeader } from './PostSearchSidebarParentSubsiteHeader';
import { PostSearchSidebarSearchLink } from './PostSearchSidebarSearchLink';

type Props = {
  homePageUrl: string;
  subsite: CmsApiSubsite;
  parentSubsite: CmsApiSubsite | undefined;
  isSubscribed: boolean;
  onSubscribe: (event: MouseEvent<HTMLButtonElement>) => void;
  author: CmsApiAuthor | undefined;
  authorSlug: CmsApiAuthorSlug | undefined;
  onRemoveAuthor: () => void;
  tag: CmsApiTag | undefined;
  tagSlug: CmsApiTagSlug | undefined;
  onRemoveTag: () => void;
  categories: CmsApiCategory[] | undefined;
  selectedCategorySlugs: CmsApiCategorySlug[];
  onSelectParentCategory: (categorySlug: CmsApiCategorySlug) => void;
  onDeselectParentCategory: () => void;
  onSelectSubCategory: (categorySlug: CmsApiCategorySlug) => void;
  onDeselectSubCategory: (categorySlug: CmsApiCategorySlug) => void;
};

export function PostSearchSidebar({
  homePageUrl,
  subsite,
  parentSubsite,
  isSubscribed,
  onSubscribe,
  author,
  authorSlug,
  onRemoveAuthor,
  tag,
  tagSlug,
  onRemoveTag,
  categories,
  selectedCategorySlugs,
  onSelectParentCategory,
  onDeselectParentCategory,
  onSelectSubCategory,
  onDeselectSubCategory,
}: Props) {
  const categoriesLoaded =
    subsite.includedSubsites.length > 0 || Boolean(categories);

  const categoryNameBySlug = useMemo(() => {
    const record: Record<string, string> = {};

    categories?.forEach((category) => {
      record[category.slug] = category.name;
    });

    return record;
  }, [categories]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
      <header
        data-qa-id="search-results-title"
        style={{ display: 'flex', flexDirection: 'column', gap: 8 }}
      >
        {parentSubsite?.homePage && (
          <PostSearchSidebarParentSubsiteHeader
            homePage={parentSubsite.homePage}
            publicName={parentSubsite.publicName}
          />
        )}

        <H1 m={0}>{subsite.publicName}</H1>

        {subsite.searchBlurb && (
          <PostSearchSidebarSearchBlurb>
            {subsite.searchBlurb}
          </PostSearchSidebarSearchBlurb>
        )}
      </header>

      <Divider marginTop={0} marginBottom={8} />

      <section style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
        <PostSearchSidebarPillsContainer>
          {authorSlug && (
            <PostSearchResultsFilterPill
              text={author?.name || authorSlug}
              isLoading={!author}
              clearFilter={onRemoveAuthor}
            />
          )}

          {tagSlug && (
            <PostSearchResultsFilterPill
              text={tag?.tag || tagSlug}
              isLoading={!tag}
              clearFilter={onRemoveTag}
            />
          )}

          {selectedCategorySlugs.map((categorySlug) => {
            const categoryName = categoryNameBySlug[categorySlug];

            return (
              <PostSearchResultsFilterPill
                key={categorySlug}
                text={categoryName || categorySlug}
                isLoading={!categoryName}
                clearFilter={onDeselectParentCategory}
              />
            );
          })}
        </PostSearchSidebarPillsContainer>

        <PostSearchInputContainer
          homePageUrl={homePageUrl}
          searchPlaceholderText={subsite.searchPlaceholderText}
          usageContext="searchResults"
        />
      </section>

      {(subsite.includedSubsites.length > 0 ||
        (categories && categories.length > 0)) && (
        <section data-qa-id="search-filters">
          <Divider marginTop={8} marginBottom={16} />

          {subsite.includedSubsites.length > 0 ? (
            <PostSearchIncludedSubsites
              parentSubsite={subsite}
              subsites={subsite.includedSubsites}
            />
          ) : (
            <PostSearchCategoryFilter
              allCategories={categories}
              selectedCategorySlugs={selectedCategorySlugs}
              label={subsite.categoryLabel}
              onSelectParentCategory={onSelectParentCategory}
              onDeselectParentCategory={onDeselectParentCategory}
              onSelectSubCategory={onSelectSubCategory}
              onDeselectSubCategory={onDeselectSubCategory}
            />
          )}
        </section>
      )}

      {!categoriesLoaded && <PostSearchLoadingFilters />}

      {categoriesLoaded && subsite.canSubscribe && (
        <section style={{ marginLeft: -8 }}>
          <Button
            type="button"
            variant="tertiary"
            onClick={onSubscribe}
            data-qa-id="subscribe-to-subsite"
            size="medium"
            disabled={isSubscribed}
          >
            <SubscribeButtonContents>
              {isSubscribed && (
                <AnimatedCheckmark colorName="brandGreen" size={16} />
              )}

              <div>
                {isSubscribed
                  ? getText('Subscribed!')
                  : subsite.subscribeText ||
                    getText('Subscribe to Our Newsletter')}
              </div>
              {!isSubscribed && <Icon size={16} name="arrow-right" />}
            </SubscribeButtonContents>
          </Button>
        </section>
      )}

      {categoriesLoaded && subsite.searchLinks.length > 0 && (
        <PostSearchSidebarSearchLinksHolder>
          {subsite.searchLinks.map((searchLink, index) => (
            <PostSearchSidebarSearchLink
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              searchLink={searchLink}
            />
          ))}
        </PostSearchSidebarSearchLinksHolder>
      )}
    </div>
  );
}
