import styled from 'styled-components';

import { cssBreakpoints } from 'theme/theme';

export const PostSearchSideFacetFilterHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PostSearchSideFacetFilterRelatedHolder = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 12px -2px 0;
`;

export const PillHolder = styled.div`
  max-width: 50%;
  padding: 4px 2px;
`;

export const SearchFilterSectionFilters = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 16px;
  overflow: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    flex-direction: column;
  }
`;
