import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { Box } from 'components/Box';
import { useFetchFn } from 'hooks/useFetchFn';
import { useMultipleQueryParamStates } from 'hooks/useMultipleQueryParamStates';
import { useSetDocumentTitle } from 'hooks/useSetDocumentTitle';
import { useCmsApiFetchAuthor } from 'modules/cms/api/cmsApiFetchAuthor';
import { useCmsApiFetchTag } from 'modules/cms/api/cmsApiFetchTag';
import { CmsApiPage, CmsApiPageMini } from 'modules/cms/api/types/CmsApiPage';
import { CmsApiCategorySlug } from 'modules/cms/api/types/CmsApiTypedId';
import { useCmsSubscribeToSubsite } from 'modules/cms/hooks/useCmsSubscribeToSubsite';
import { postSearchAlgoliaFetch } from 'modules/postSearch/algolia/postSearchAlgoliaFetch';
import { usePostSearchApiFetchSubsiteCategories } from 'modules/postSearch/api/postSearchApiFetchSubsiteCategories';
import { PostSearchResults } from 'modules/postSearch/components/Results/PostSearchResults';
import { PostSearchSidebar } from 'modules/postSearch/components/Sidebar/PostSearchSidebar';
import { gutterWidth } from 'theme/theme';
import { trackClicked } from 'utils/analytics/track/trackClicked';
import { trackEvent } from 'utils/analytics/track/trackEvent';
import { truncateToUTF8Bytes } from 'utils/string/truncateToUTF8Bytes';

import { SearchResultsContainer } from './PostSearchPage.styled';
import { PostSearchPageLocationState } from './PostSearchPageLocationState';
import { usePostSearchPageTrackDisplayedListings } from './usePostSearchPageTrackDisplayedListings';

type Props = {
  content: CmsApiPage;
};

export function PostSearchPage({ content }: Props) {
  const { subsite } = content;
  const homePage = subsite.homePage as CmsApiPageMini; // article search is only available when there's a home page, so it's not null

  useSetDocumentTitle(content.headTitle || content.title);
  const { subscribe, isSubscribed } = useCmsSubscribeToSubsite(subsite.id);

  const location = useLocation<PostSearchPageLocationState>();
  const parentSubsite = location.state?.parentSubsite;

  const [
    {
      pq: rawQuery,
      page: pageQueryParam,
      'tags.slug': tagSlug,
      'author.slug': authorSlug,
      categories: categorySlugs,
    },
    setUrlParams,
  ] = useMultipleQueryParamStates({
    names: ['pq', 'page', 'tags.slug', 'author.slug'],
    arrayNames: ['categories'],
  });

  const query = useMemo(
    () => truncateToUTF8Bytes(rawQuery || '', 512),
    [rawQuery],
  );

  const pageIndex = pageQueryParam ? parseInt(pageQueryParam, 10) - 1 : 0;

  const [categories] = usePostSearchApiFetchSubsiteCategories(
    subsite.id,
    content.locale,
  );
  const [author] = useCmsApiFetchAuthor(authorSlug);
  const [tag] = useCmsApiFetchTag(tagSlug, subsite.id);

  const [results] = useFetchFn({
    fn: postSearchAlgoliaFetch,
    args: [
      {
        query,
        pageIndex,
        subsiteId: subsite.id,
        includedSubsiteIds: subsite.includedSubsites.map(
          (includedSubsite) => includedSubsite.id,
        ),
        locale: content.locale,
        categorySlugs,
        tagSlug,
        authorSlug,
      },
    ],
  });

  const selectParentCategory = (parentCategorySlug: CmsApiCategorySlug) => {
    setUrlParams({ categories: [parentCategorySlug] });
  };

  const deselectParentCategory = () => {
    setUrlParams({ categories: undefined });
  };

  const selectSubCategory = (subCategorySlug: CmsApiCategorySlug) => {
    const wasPreviouslyParentSelected =
      categorySlugs.length === 1 &&
      categories?.find(
        (category) => categorySlugs[0] === category.slug && !category.parentId,
      );

    const newCategorySlugs = wasPreviouslyParentSelected
      ? [subCategorySlug]
      : [...categorySlugs, subCategorySlug];

    setUrlParams({ categories: newCategorySlugs });
  };

  const deselectSubCategory = (subCategorySlug: CmsApiCategorySlug) => {
    const newCategorySlugs = categorySlugs.filter(
      (slug) => slug !== subCategorySlug,
    );

    setUrlParams({ categories: newCategorySlugs });
  };

  usePostSearchPageTrackDisplayedListings({ content, results });

  return (
    <SearchResultsContainer>
      <Box
        data-qa-id="search-sidebar"
        width={[1, 1, 1, 1 / 3]}
        px={[gutterWidth / 2, null, null, gutterWidth]}
      >
        <PostSearchSidebar
          homePageUrl={homePage.url}
          subsite={subsite}
          parentSubsite={parentSubsite}
          isSubscribed={isSubscribed}
          onSubscribe={async () => {
            await subscribe();
            trackClicked('Subscribe', {
              context: 'CMS Search Results',
            });
          }}
          author={author}
          authorSlug={authorSlug}
          onRemoveAuthor={() => setUrlParams({ 'author.slug': undefined })}
          tag={tag}
          tagSlug={tagSlug}
          onRemoveTag={() => setUrlParams({ 'tags.slug': undefined })}
          categories={categories}
          selectedCategorySlugs={categorySlugs}
          onSelectParentCategory={selectParentCategory}
          onDeselectParentCategory={deselectParentCategory}
          onSelectSubCategory={selectSubCategory}
          onDeselectSubCategory={deselectSubCategory}
        />
      </Box>

      <Box
        width={[1, 1, 1, 2 / 3]}
        px={[gutterWidth / 2, null, null, gutterWidth]}
        pt={[gutterWidth, null, null, 0]}
        data-qa-id="search-results"
      >
        <PostSearchResults
          homePageUrl={homePage.url}
          results={results}
          showFeaturedSearch={
            pageIndex === 0 &&
            !(query?.length || authorSlug || tagSlug) &&
            subsite.hasFeaturedPostInSearchResults
          }
          subsite={subsite}
          categories={categories}
          onPageIndexChange={(newPageIndex) => {
            trackEvent('POST: Changed Search Page Number', {
              new_page: newPageIndex,
            });
          }}
        />
      </Box>
    </SearchResultsContainer>
  );
}
