import type { SearchResponse } from '@algolia/client-search';
import debounce from 'lodash/debounce';

import { CmsApiSubsite } from 'modules/cms/api/types/CmsApiSubsite';
import { PostSearchAlgoliaHit } from 'modules/postSearch/algolia/types/PostSearchAlgoliaHit';
import { trackEvent } from 'utils/analytics/track/trackEvent';

const TRACK_DISPLAYED_POST_WAIT = 500;

type Args = {
  results: SearchResponse<PostSearchAlgoliaHit>;
  subsite: CmsApiSubsite;
};

function postSearchPageTrackDisplayedPosts({ results, subsite }: Args) {
  const { hits } = results;

  const articleIds = hits.map((h) => h.objectID);

  const rankingInfo = hits.map(({ _rankingInfo, objectID }) => ({
    rankingInfo: _rankingInfo,
    objectID,
  }));

  trackEvent('Displayed CMS Articles', {
    article_ids: articleIds,
    ranking_info: rankingInfo,
    subsite_id: subsite.id,
    subsite_name: subsite.publicName,
    page_type: 'Searches for Articles',
    current_page: results.page,
    search_query: results.query,
    number_of_results: results.nbHits,
    number_of_pages: results.nbPages,
  });
}

export const debouncedTrackDisplayedPosts = debounce(
  postSearchPageTrackDisplayedPosts,
  TRACK_DISPLAYED_POST_WAIT,
);
