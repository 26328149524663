import { FetchEndpoint } from 'utils/endpoint/FetchEndpoint';

import { CmsApiTag } from './types/CmsApiTag';
import { CmsApiSubsiteId, CmsApiTagSlug } from './types/CmsApiTypedId';

type ApiResponse = {
  tag: CmsApiTag;
};

function mapResponse(response: ApiResponse) {
  return response.tag;
}

export const endpoint = new FetchEndpoint({
  urlFactory: (slug: CmsApiTagSlug | undefined, subsiteId: CmsApiSubsiteId) =>
    slug ? `/data/website/subsites/${subsiteId}/tags/${slug}` : undefined,
  mapResponse,
});

// Exports

export const useCmsApiFetchTag = endpoint.createUseFetchHook();
