import { CmsApiCategory } from 'modules/cms/api/types/CmsApiCategory';
import { CmsApiSubsiteId } from 'modules/cms/api/types/CmsApiTypedId';
import { FetchEndpoint } from 'utils/endpoint/FetchEndpoint';

type ApiResponse = {
  categories: CmsApiCategory[];
  locale: UserLocale;
};

function mapResponse(response: ApiResponse) {
  return response.categories;
}

const endpoint = new FetchEndpoint({
  urlFactory: (subsiteId: CmsApiSubsiteId, locale: UserLocale) =>
    `/data/website/subsites/${subsiteId}/categories?lang=${locale}`,
  mapResponse,
});

// Exports

export const usePostSearchApiFetchSubsiteCategories =
  endpoint.createUseFetchHook();
