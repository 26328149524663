import { Box } from 'components/Box';
import { Button } from 'components/Button/Button';
import { Hide } from 'components/Hide';
import { Body } from 'components/Text/Body';
import { H2 } from 'components/Text/H2';
import { H4 } from 'components/Text/H4';
import { Tile } from 'components/Tile/Tile';
import { getStaticImageSrc } from 'utils/getImageSrc';

export function PostSearchEmptyResultsMessage() {
  return (
    <div>
      <Box
        display={['block', null, null, 'flex']}
        justifyContent="space-between"
        mb={4}
      >
        <div style={{ flex: '1 1 auto', paddingRight: 16 }}>
          <H4 mb={0}>{getText('No articles match your search')}</H4>
        </div>
      </Box>

      <Tile
        $gradientBorder
        position="relative"
        display="flex"
        flex="1"
        py={5}
        px={4}
        borderRadius="4px"
      >
        <Box display="flex" justifyContent="center" width={[0, 0, '50%']}>
          <Hide xs sm>
            <img
              src={getStaticImageSrc('broken-computer.svg')}
              alt="Illustration"
            />
          </Hide>
        </Box>
        <Box
          textAlign={['center', 'center', 'left']}
          width={['100%', '100%', '50%']}
        >
          <H2 mb={2}>{getText('Looking for additional help?')}</H2>
          <Body mb={4}>{getText('Visit our Help Center for more info.')}</Body>
          <Button
            type="link"
            variant="primary"
            href={getText(
              /* TRANSLATORS: search: help center link url */
              '/en/help',
            )}
          >
            {
              /* TRANSLATORS: search: help center link text */
              getText('Help Center')
            }
          </Button>
        </Box>
      </Tile>
    </div>
  );
}
