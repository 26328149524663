import queryString from 'query-string';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

type Args<TName extends string, TArrayName extends string> = {
  names: TName[];
  arrayNames?: TArrayName[];
};

export function useMultipleQueryParamStates<
  TName extends string,
  TArrayName extends string = never,
>({
  names,
  arrayNames,
}: Args<TName, TArrayName>): [
  Partial<Record<TName, string>> & Record<TArrayName, string[]>,
  (
    values: Partial<
      Record<TName, string | undefined> &
        Record<TArrayName, string[] | undefined>
    >,
  ) => void,
] {
  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = queryString.parse(location.search);

  const values = names.reduce((acc, name) => {
    const rawValue = searchParams[name];
    const value = Array.isArray(rawValue) ? rawValue[0] : rawValue;
    return { ...acc, [name]: value || undefined };
  }, {});

  const arrayValues =
    arrayNames?.reduce((acc, arrayName) => {
      const rawValue = searchParams[arrayName];

      const value = Array.isArray(rawValue)
        ? rawValue
        : [rawValue].filter(Boolean);

      return { ...acc, [arrayName]: value || undefined };
    }, {}) || {};

  const setValues = useCallback(
    (
      newValues: Partial<
        Record<TName, string | undefined> &
          Record<TArrayName, string[] | undefined>
      >,
    ) => {
      const search = queryString.stringify({ ...searchParams, ...newValues });
      navigate({ pathname: location.pathname, hash: location.hash, search });
    },
    [navigate, location, searchParams],
  );

  return [
    { ...values, ...arrayValues } as Partial<
      Record<TName, string | undefined>
    > &
      Record<TArrayName, string[]>,
    setValues,
  ];
}
