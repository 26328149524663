import { BodySmall } from 'components/Text/BodySmall';
import { CmsApiMiniPage } from 'modules/cms/api/types/CmsApiMiniPage';
import { CmsApiMiniSubsite } from 'modules/cms/api/types/CmsApiMiniSubsite';
import { CmsApiSubsite } from 'modules/cms/api/types/CmsApiSubsite';

import { PostSearchIncludedSubsitesLinksHolder } from './IncludedSubsites.styled';
import { SubsiteLink } from './SubsiteLink';

type Props = {
  subsites: CmsApiMiniSubsite[];
  parentSubsite: CmsApiSubsite;
};

export function PostSearchIncludedSubsites({ subsites, parentSubsite }: Props) {
  return (
    <div data-qa-id="side-filter-facet-subsites">
      <BodySmall>{getText('Category')}</BodySmall>

      <PostSearchIncludedSubsitesLinksHolder data-qa-id="search-filter-section-filters">
        {subsites.map((subsite) => {
          // search is only available when there's a home page
          const homePage = subsite.homePage as CmsApiMiniPage;

          return (
            <SubsiteLink
              key={subsite.id}
              parentSubsite={parentSubsite}
              icon={subsite.icon}
              label={subsite.publicName}
              url={homePage.url}
            />
          );
        })}
      </PostSearchIncludedSubsitesLinksHolder>
    </div>
  );
}
