import { sprintf } from 'sprintf-js';
import styled from 'styled-components';

import { Pill } from 'components/Pill/Pill';

const FilterPillContainer = styled.div`
  padding: 4px 2px;
`;

type Props = {
  text: string;
  isLoading?: boolean;
  clearFilter: () => void;
};

export function PostSearchResultsFilterPill({
  text,
  isLoading,
  clearFilter,
}: Props) {
  return (
    <FilterPillContainer data-qa-id="filter-pill">
      <Pill
        onClick={clearFilter}
        title={sprintf(getText(`Clear %(option)s filter`), {
          option: text,
        })}
        text={text}
        isLoading={isLoading}
      />
    </FilterPillContainer>
  );
}
