import { FetchEndpoint } from 'utils/endpoint/FetchEndpoint';

import { CmsApiAuthor } from './types/CmsApiAuthor';
import { CmsApiAuthorSlug } from './types/CmsApiTypedId';

type ApiResponse = {
  author: CmsApiAuthor;
};

function mapResponse(response: ApiResponse) {
  return response.author;
}

const endpoint = new FetchEndpoint({
  urlFactory: (slug: CmsApiAuthorSlug | undefined) =>
    slug ? `/data/website/authors/${slug}` : undefined,
  mapResponse,
});

// Exports

export const useCmsApiFetchAuthor = endpoint.createUseFetchHook();
