import { algoliaQueryStringFilter } from './algoliaQueryStringFilter';

export function algoliaQueryStringArrayFilter(facet: string, values: string[]) {
  if (values.length === 0) return '';

  const mappedFacetValuePairs = values.map((value) =>
    algoliaQueryStringFilter(facet, value),
  );

  return `(${mappedFacetValuePairs.join(' OR ')})`;
}
