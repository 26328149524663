import styled from 'styled-components';

import { bodySmallText, colors } from 'theme/theme';

export const PostSearchSidebarPillsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  &:empty {
    display: none;
  }
`;

export const SubscribeButtonContents = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
`;

export const PostSearchSidebarSearchBlurb = styled.div`
  ${bodySmallText};
  position: relative;
  color: ${colors.lightContentGrey};
`;

export const PostSearchSidebarSearchLinksHolder = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0 8px;
`;
