import { sprintf } from 'sprintf-js';

import { Box } from 'components/Box';
import { Icon } from 'components/Icon/Icon';
import { HeadingSmall } from 'components/Text/HeadingSmall';
import { VersionedRouterLink } from 'components/VersionedLink/VersionedRouterLink';
import { CmsApiMiniPage } from 'modules/cms/api/types/CmsApiMiniPage';

type Props = {
  homePage: CmsApiMiniPage;
  publicName: string;
};

export function PostSearchSidebarParentSubsiteHeader({
  homePage,
  publicName,
}: Props) {
  return (
    <HeadingSmall fontWeight="semiBold" m={0}>
      <VersionedRouterLink
        to={`/${homePage.url}?pq=`}
        state={{ parentSubsite: null }}
        style={{ textDecoration: 'none' }}
        data-qa-id="back-to-subsite-link"
      >
        <Icon name="chevron-left" size={10} />

        <Box component="span" ml="4px">
          {sprintf(getText('Back to %(name)s'), {
            name: publicName,
          })}
        </Box>
      </VersionedRouterLink>
    </HeadingSmall>
  );
}
